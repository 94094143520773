<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="
                    card card-custom
                    gutter-b
                    bg-transparent
                    shadow-none
                    border-0
                  ">
                                    <div class="
                      card-header
                      align-items-center
                      border-bottom-dark
                      px-0
                    ">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Supplier Due Payment
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-10 mx-auto">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">

                                        <div class="row justify-content-start">
                                            <div class="col-12 col-md-3">
                                                <div>
                                                    <table class="table right-table mb-0">
                                                        <tbody>
                                                            <tr class="d-flex align-items-center justify-content-between">
                                                                <th class="border-0">
                                                                    <h5 class="font-size-h5 mb-0 font-size-bold text-dark">
                                                                        Subtotal {{ getCurrencyTitle() }}:</h5>
                                                                </th>
                                                                <td
                                                                    class="border-0 justify-content-end d-flex text-black-50 font-size-base">
                                                                    {{ purchase.total_amount }}</td>
                                                            </tr>

                                                            <tr class="d-flex align-items-center justify-content-between">
                                                                <th class="border-0">
                                                                    <h5 class="font-size-h5 mb-0 font-size-bold text-dark">
                                                                        Due Amount {{ getCurrencyTitle() }}:</h5>
                                                                </th>
                                                                <td
                                                                    class="border-0 justify-content-end d-flex text-black-50 font-size-base">
                                                                    {{ purchase.amount_due }}</td>
                                                            </tr>
                                                            <tr class="d-flex align-items-center justify-content-between">
                                                                <th class="border-0">
                                                                    <h5 class="font-size-h5 mb-0 font-size-bold text-dark">
                                                                        Discount: {{ getCurrencyTitle() }}</h5>
                                                                </th>
                                                                <td
                                                                    class="border-0 justify-content-end d-flex text-black-50 font-size-base">
                                                                    <h5 class="font-size-h5 mb-0 font-size-bold text-dark">
                                                                        {{purchase.discount_amount}}</h5>
                                                                </td>
                                                            </tr>
                                                            <tr class="d-flex align-items-center justify-content-between">
                                                                <th class="border-0">
                                                                    <h5 class="font-size-h5 mb-0 font-size-bold text-dark">
                                                                        Amount Paid {{ getCurrencyTitle() }}:</h5>
                                                                </th>
                                                                <td
                                                                    class="border-0 justify-content-end d-flex text-black-50 font-size-base">
                                                                    <h5 class="font-size-h5 mb-0 font-size-bold text-dark">
                                                                        {{purchase.amount_paid}}</h5>
                                                                    
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-10 col-xl-10 mx-auto">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <form @submit.prevent="saveUpdate">
                                            <div class="form-group row">
                                                <div class="col-md-6 mx-auto">
                                                    <label class="text-body">Payment(USD)</label>
                                                    <fieldset class="form-group mb-3">
                                                        <input type="text" name="text" class="form-control" v-model="amount"
                                                            @change="onChange($event)" />
                                                    </fieldset>
                                                    <small class="form-text text-danger" v-if="errors.has('amount')"
                                                        v-text="errors.get('amount')"></small>
                                                </div>
                                                <div class="col-12 d-flex justify-content-center">
                                                    <button href="#" class="btn btn-secondary white">
                                                        Submit
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ErrorHandling from "./../../ErrorHandling";
import Multiselect from "vue-multiselect";
import { useRoute } from "vue-router";

export default {

    components: { Multiselect },
    data() {
        return {
            display_form: 0,
            amount: "",
            token: [],
            purchase: [],
            currency: [],
            errors: new ErrorHandling(),
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
        };
    },
    methods: {
        onChange(e) {
            console.log(e, this.$route.params.id)
            let amount = event.target.value;  // Actual assignment
            //  console.log(amount)

        },
        async saveUpdate(e) {

            //    console.log(this.amount)
            this.$parent.loading = true;
            let data = new FormData();
            data.append("amount", this.amount);
            var url = '/api/admin/supplier/pay/' + this.$route.params.id;

            await axios
                .post(url, data, this.token)
                .then(res => {
                    if (res.data.status === 200) {
                        this.$toaster.success(res.data.message);
                        this.errors.record('');
                        this.amount = '';
                        this.fetchPurchase(this.$route.params.id);
                    } else {
                        this.$toaster.error(res.data.message);
                        this.$parent.loading = false;
                    }
                }).catch((error) => {
                    this.error_message = "";
                    this.errors = new ErrorHandling();
                    if (error.response.status == 422) {
                        if (error.response.data.status == "Error") {
                            this.error_message = error.response.data.message;
                        } else {
                            this.errors.record(error.response.data.errors);
                        }
                    }
                }).finally(() => (this.$parent.loading = false));
        },
        fetchPurchase(id) {
            this.errors = new ErrorHandling();
            this.$parent.loading = true;

            axios.get('/api/admin/purchase/' + id + '?getSupplier=1&getProductDetail=1&getProductCombinationDetail=1&getWarehouse=1', this.token)
                .then(res => {
                    if (res.data.status == "Success") {
                        this.display_table = true;
                        this.purchase = res.data.data;
                        this.selectedProducts = [];
                        this.customIndex = 0;
                        var arr = {};
                        for (var k = 0; k < res.data.data.detail.length; k++) {
                            if (res.data.data.detail[k].product.product_type == 'simple') {
                                arr.title = res.data.data.detail[k].product.detail.length > 0 ? res.data.data.detail[k].product.detail[0].title : '';
                                arr.qty = res.data.data.detail[k].product_qty;
                                arr.price = res.data.data.detail[k].product_price;
                                console.log(arr);
                                this.selectedProducts.push(arr);
                                arr = {};
                            } else {
                                if (res.data.data.detail[k].product.combination_detail.length > 0) {
                                    for (var i = 0; i < res.data.data.detail[k].product.combination_detail.length; i++) {
                                        var combination_name = '';
                                        if (res.data.data.detail[k].product.combination_detail[i].product_combination_id == res.data.data.detail[k].product_combination_id) {
                                            arr.product_combination_id = res.data.data.detail[k].product.combination_detail[i].product_combination_id;

                                            if (res.data.data.detail[k].product.combination_detail[i].combination.length > 0) {
                                                for (var j = 0; j < res.data.data.detail[k].product.combination_detail[i].combination.length; j++) {
                                                    if (j == 0) {
                                                        combination_name = res.data.data.detail[k].product.combination_detail[i].combination[j].variation.detail[0].name
                                                    } else {
                                                        combination_name += '-' + res.data.data.detail[k].product.combination_detail[i].combination[j].variation.detail[0].name
                                                    }
                                                    // console.log('i=' + i + 'j=' + j);
                                                }
                                            }
                                            arr.qty = res.data.data.detail[k].product_qty;
                                            arr.price = res.data.data.detail[k].product_price;
                                            arr.title = res.data.data.detail[k].product.detail.length > 0 ? res.data.data.detail[k].product.detail[0].title + ' (' + combination_name + ')' : '';
                                            this.selectedProducts.push(arr);
                                            arr = {};
                                        }
                                    }
                                }
                            }
                        }

                    }
                })
                .finally(() => (this.$parent.loading = false));
        },
        getCurrencyTitle() {
            return this.currency == null ? '' : '(' + this.currency.title + ')';
        },
        fetchCurrency() {
            this.$parent.loading = true;
            var token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            var responseData = {};

            axios.get('/api/admin/currency?is_default=1', config)
                .then(res => {
                    if (res.data.status == "Success") {
                        this.currency = res.data.data;
                    }
                })
                .finally(() => (this.$parent.loading = false));
        },

    },
    mounted() {
        var token = localStorage.getItem("token");
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        this.fetchCurrency();
        this.fetchPurchase(this.$route.params.id);

    }
};
</script>
