<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Supplier Payment History
                                            </h3>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <div>
                                            <div class=" table-responsive" id="printableTable">
    
                                                <div id="purchase_wrapper" class="dataTables_wrapper no-footer">
    
                                                <div class="dataTables_length" id="purchase_length"><label>Show
                                                <select name="purchase_length" aria-controls="purchase" class="" v-model="limit" v-on:change="fetchPurchases()">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                                </select> entries</label></div>
    
                                                <div id="purchase_filter" class="dataTables_filter">
                                                    <!-- <label>Search:<input type="search" class="" placeholder="" aria-controls="purchase" v-model="searchParameter" @keyup="fetchPurchases()"></label> -->
                                                    </div>
                                                    <table id="purchase" class="display dataTable no-footer" purchase="grid">
                                                        <thead class="text-body">
                                                            <tr purchase="row">
                                                                <th class="sorting" tabindex="0" aria-controls="purchase" rowspan="1" colspan="1" aria-sort="ascending" aria-label="ID: activate to sort column descending" style="width: 31.25px;" @click="sorting('id')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'id'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'id' ? 'sorting_desc' : 'sorting'">
                                                                    Supplier Pay ID
                                                                </th>
                                                                
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action">
                                                                    Payment Date
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action">
                                                                    Payer Name
                                                                </th>
                                                                <!-- <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action">
                                                                    Purchase Status
                                                                </th> -->
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action">
                                                                  Payment Amount
                                                                </th>
                                                                
                                                               <th class="sorting" tabindex="0" aria-controls="purchase" rowspan="1" colspan="1" aria-label="purchase: activate to sort column ascending" style="width: 95.5288px;" @click="sorting('amount_paid')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'amount_paid'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'amount_paid' ? 'sorting_desc' : 'sorting'">
                                                                    Paid Amount {{getCurrencyTitle()}}
                                                                </th>
                                                                <th class="sorting" tabindex="0" aria-controls="purchase" rowspan="1" colspan="1" aria-label="purchase: activate to sort column ascending" style="width: 95.5288px;" @click="sorting('discount_amount')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'discount_amount'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'discount_amount' ? 'sorting_desc' : 'sorting'">
                                                                    Discount {{getCurrencyTitle()}}
                                                                </th>
                                                                <th class="sorting" tabindex="0" aria-controls="purchase" rowspan="1" colspan="1" aria-label="purchase: activate to sort column ascending" style="width: 95.5288px;" @click="sorting('amount_due')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'amount_due'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'amount_due' ? 'sorting_desc' : 'sorting'">
                                                                    Due Amount {{getCurrencyTitle()}}
                                                                </th>
                                                                <th class="sorting" tabindex="0" aria-controls="purchase" rowspan="1" colspan="1" aria-label="purchase: activate to sort column ascending" style="width: 95.5288px;" @click="sorting('total_amount')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'total_amount'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'total_amount' ? 'sorting_desc' : 'sorting'">
                                                                    Total Amount {{getCurrencyTitle()}}
                                                                </th>
                                                                  <!-- <th class="sorting" tabindex="0" aria-controls="purchase" rowspan="1" colspan="1" aria-label="purchase: activate to sort column ascending" style="width: 95.5288px;" @click="sorting('amount_due')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'amount_due'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'amount_due' ? 'sorting_desc' : 'sorting'">
                                                                    Supplier Due Payment
                                                                </th>
                                                                <th class="no-sort sorting_disabled" tabindex="0" aria-controls="purchase" rowspan="1" colspan="1" aria-label="purchase: activate to sort column ascending" style="width: 95.5288px;" @click="sorting('amount_due')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'amount_due'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'amount_due' ? 'sorting_desc' : 'sorting'">
                                                                    View
                                                                </th> -->
                                                            </tr>
                                                        </thead>
                                                        <tbody class="kt-table-tbody text-dark">
                                                            <tr class="kt-table-row kt-table-row-level-0 odd" purchase="row" v-for="val in supplier_payment" v-bind:key="val.id">
                                                                <td class="sorting_1">
                                                                <router-link :to="'/purchase-detail/'+val.purchase_id">
                                                                    {{val.purchase_id}}
                                                                </router-link>
                                                                </td>
                                                              
                                                                <td>
                                                                    {{ new Date(val.created_at) }}
                                                                </td>
                                                                <td>
                                                                    {{ val.payer != null ? val.payer.name :"" }}
                                                                </td>
                                                                <!-- <td>
                                                                    {{ val.val_status }}
                                                                </td> -->
                                                                <td>
                                                                    {{ val.amount}}
                                                                </td>
                                                              
                                                                <td>
                                                                    {{ val.purchase.amount_paid }}
                                                                </td>
                                                                <td>
                                                                    {{ val.purchase.discount_amount }}
                                                                </td>
                                                                <td>
                                                                    {{ val.purchase.amount_due }}
                                                                </td>
                                                                <td>
                                                                    {{ val.purchase.total_amount }}
                                                                </td>
                                                                 <!-- <td>
                                                                    <router-link  :to="'/supplier-due-payment/'+purchase.purchase_id">
                                                                   <button class="btn-sm btn-secondary text-white">Payment</button>  
                                                                </router-link>
                                                                </td>
                                                                 <router-link :to="'/purchase-detail/'+purchase.purchase_id">
                                                                    View
                                                                </router-link> -->
    
                                                               
    
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <!-- <ul class="pagination pagination-sm m-0 float-right">
                                                        <li v-bind:class="[{disabled: !pagination.prev_page_url}]"><a class="page-link" href="#" @click="fetchPurchases(pagination.prev_page_url)">Previous</a></li>
    
                                                        <li class="disabled"><a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</a></li>
    
                                                        <li v-bind:class="[{disabled: !pagination.next_page_url}]" class="page-item"><a class="page-link" href="#" @click="fetchPurchases(pagination.next_page_url)">Next</a></li>
                                                    </ul> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
     
    </div>
    </template>
    
    <script>
    import ErrorHandling from "./../../ErrorHandling";
    export default {
        data() {
            return {
                display_form: 0,
                supplier_payment:[],
                searchParameter: '',
                sortBy: 'id',
                sortType: 'ASC',
                limit: 10,
                error_message: '',
                edit: false,
                actions: false,
                pagination: {},
                request_method: "",
                is_default: "0",
                token: [],
                currency: [],
                errors: new ErrorHandling(),
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
            };
        },
    
        methods: {
            fetchCurrency() {
                this.$parent.loading = true;
                var token = localStorage.getItem('token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                var responseData = {};
    
                axios.get('/api/admin/currency?is_default=1', config)
                    .then(res => {
                        if (res.data.status == "Success") {
                            this.currency = res.data.data;
                        }
                    })
                    .finally(() => (this.$parent.loading = false));
            },
            async fetchSupplierPay(page_url) {
                this.$parent.loading = true;
                let vm = this;
                page_url = "/api/admin/supplier/pay/history";
                var arr = page_url.split('?');
    
                if (arr.length > 1) {
                    page_url += '&limit='+this.limit;
                }
                else{
                    page_url += '?limit='+this.limit;
                }
                if(this.searchParameter != null){
                    page_url += '&searchParameter='+this.searchParameter;
                }
                page_url += '&sortBy='+this.sortBy+'&sortType='+this.sortType;
                page_url +='&getSupplier=1&getPurchaseDetail=1&getProductDetail=1&getProductCombinationDetail=1&getWarehouse=1';
    
                var responseData = {};
    
                await axios.get(page_url, this.token).then(res => {
                    this.supplier_payment = res.data.data.data;
                    // vm.makePagination(res.data.meta, res.data.links);
                    console.log(this.purchase, res.data.data)
                })
                .finally(() => (this.$parent.loading = false));
            },
    
            makePagination(meta, links) {
                let pagination = {
                    current_page: meta.current_page,
                    last_page: meta.last_page,
                    next_page_url: links.next,
                    prev_page_url: links.prev
                };
    
                this.pagination = pagination;
            },
            sorting(sortBy){
                this.sortBy = sortBy;
                this.sortType = this.sortType == 'asc' || this.sortType == 'ASC' ? this.sortType='desc' : this.sortType = 'asc';
                this.fetchSupplierPay();
            },
            getCurrencyTitle(){
                return this.currency == null ? '' : '('+this.currency.title+')';
            }
        },
        mounted() {
    
            var token = localStorage.getItem('token');
            this.token = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            this.fetchCurrency();
            this.fetchSupplierPay();
        },
        props: ['loading'],
    };
    </script>
    