import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
window.axios = require('axios');
import InputTag from 'vue-input-tag'
import Toaster from 'v-toaster';
import AttachImage from './Components/admin/AttachImage';
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-toaster/dist/v-toaster.css';
import VueNestable from 'vue-nestable'
// import component and stylesheet
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'



import VueApexCharts from 'vue-apexcharts'
import VueSimpleAlert from "vue-simple-alert";
// optional set default imeout, the default is 10000 (10 seconds).
Vue.use(VueApexCharts)
Vue.use(VueRouter)
Vue.use(Toaster, { timeout: 2000 })
Vue.component('input-tag', InputTag)
Vue.component('attach-image', AttachImage)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueNestable)
Vue.use(VueSimpleAlert);
// see docs for available options
const datepickerOptions = {}
// make sure we can use it in our components
Vue.use(AirbnbStyleDatepicker, datepickerOptions)
let routes = [
    {
        path: '/', component: require('./components/admin/Main.vue').default, name: 'main',
        children: [
            { path: '/', component: require('./components/admin/Dashboard.vue').default, name: 'dashboard', meta: { auth: true } },
            { path: '/bussiness-setting', component: require('./components/admin/BusinessSetting.vue').default, name: 'business-setting', meta: { auth: true } },
            { path: '/website-setting', component: require('./components/admin/WebsiteSetting.vue').default, name: 'website-setting', meta: { auth: true } },
            { path: '/app-setting', component: require('./components/admin/AppSetting.vue').default, name: 'mobile-setting', meta: { auth: true } },
            // { path: '/biller', component: require('./components/admin/Biller.vue').default, name: 'biller', meta: { auth: true } },
            { path: '/customer', component: require('./components/admin/Customer.vue').default, name: 'customer-list', meta: { auth: true } },
            // { path: '/purchasers', component: require('./components/admin/Purchaser.vue').default, name: 'purchaser-list', meta: { auth: true } },
            { path: '/suppliers', component: require('./components/admin/Supplier.vue').default, name: 'supplier-list', meta: { auth: true } },


            { path: '/product-unit', component: require('./components/admin/ProductUnit.vue').default, name: 'product-unit-list', meta: { auth: true } },
            { path: '/product-attribute', component: require('./components/admin/ProductAttribute.vue').default, name: 'product-attribute-list', meta: { auth: true } },
            { path: '/product-variation', component: require('./components/admin/ProductVariation.vue').default, name: 'product-variation-list', meta: { auth: true } },
            { path: '/product-brand', component: require('./components/admin/ProductBrand.vue').default, name: 'product-brand-list', meta: { auth: true } },
            { path: '/product-category', component: require('./components/admin/ProductCategory.vue').default, name: 'product-category-list', meta: { auth: true } },
            { path: '/product-reviews', component: require('./components/admin/ProductReviews.vue').default, name: 'product-reviews', meta: { auth: true } },



            { path: '/quotation-detail/:id', component: require('./components/admin/QuotationDetail.vue').default, name: 'quotation-detail', meta: { auth: true } },
            { path: '/warehouse', component: require('./components/admin/Warehouse.vue').default, name: 'warehouse-list', meta: { auth: true } },
            { path: '/users', component: require('./components/admin/User.vue').default, name: 'user-list', meta: { auth: true } },

            { path: '/roles', component: require('./components/admin/Role.vue').default, name: 'role-list', meta: { auth: true } },
            { path: '/roles/permission/:id/:name', component: require('./components/admin/PermissionRole.vue').default, name: 'role-list', meta: { auth: true } },

            { path: '/roles/permissions/add', component: require('./components/admin/RolesPermissions.vue').default, name: 'role-manage', meta: { auth: true } },

            { path: '/roles/permissions/:id/:name', component: require('./components/admin/RolesPermissions.vue').default, name: 'role-list', meta: { auth: true } },

            { path: '/languages', component: require('./components/admin/Language.vue').default, name: 'language-list', meta: { auth: true } },
            { path: '/currencies', component: require('./components/admin/Currency.vue').default, name: 'currency-list', meta: { auth: true } },
            { path: '/payment-methods', component: require('./components/admin/PaymentMethod.vue').default, name: 'payment-methods-list', meta: { auth: true } },
            { path: '/coupon-setting', component: require('./components/admin/CouponSetting.vue').default, name: 'coupon-list', meta: { auth: true } },
            { path: '/country-setting', component: require('./components/admin/Country.vue').default, name: 'country-list', meta: { auth: true }},
            { path: '/state-setting', component: require('./components/admin/State.vue').default, name: 'state-list', meta: { auth: true}},
            { path: '/city-setting', component: require('./components/admin/City.vue').default, name: 'city-list', meta: { auth: true}},
            { path: '/shippment-with-city-setting', component: require('./components/admin/ShippmentWithCity.vue').default, name: 'shippment-with-city-list', meta: { auth: true}},
            { path: '/tax-setting', component: require('./components/admin/TaxSetting.vue').default, name: 'tax-setting-list', meta: { auth: true } },
            { path: '/tax-rate-setting', component: require('./components/admin/TaxRateSetting.vue').default, name: 'tax-setting-rate-list', meta: { auth: true } },
            { path: '/shipping-methods', component: require('./components/admin/ShippingMethod.vue').default, name: 'shipping-methods-list', meta: { auth: true } },

            { path: '/media-setting', component: require('./components/admin/MediaSetting.vue').default, name: 'media-setting', meta: { auth: true } },
            { path: '/media', component: require('./components/admin/Media.vue').default, name: 'manage-media', meta: { auth: true } },
            { path: '/media/:id', component: require('./components/admin/MediaDetail.vue').default, name: 'manage-media', meta: { auth: true } },

            { path: '/purchases', component: require('./components/admin/ListPurchase.vue').default, name: 'purchase-list', meta: { auth: true } },
            { path: '/add-purchase', component: require('./components/admin/AddPurchase.vue').default, name: 'purchase-manage', meta: { auth: true } },
            { path: '/purchase-detail/:id', component: require('./components/admin/PurchaseDetail.vue').default, name: 'purchase-list', meta: { auth: true } },

            { path: '/add-sale', component: require('./components/admin/AddSale.vue').default, name: 'sale-manage', meta: { auth: true } },
            { path: '/sales', component: require('./components/admin/ListSale.vue').default, name: 'sale-list', meta: { auth: true } },
            { path: '/sale-detail/:id', component: require('./components/admin/SaleDetail.vue').default, name: 'sale-list', meta: { auth: true } },
            { path: '/orders', component: require('./components/admin/Orders.vue').default, name: 'order-list', meta: { auth: true } },
            { path: '/order/:id', component: require('./components/admin/OrderDetail.vue').default, name: 'order-list', meta: { auth: true } },

            { path: '/add-sale-return', component: require('./components/admin/AddSaleReturn.vue').default, name: 'sale-return-manage', meta: { auth: true } },
            // { path: '/sale-return', component: require('./components/admin/ListSaleReturn.vue').default, name: 'sale-return-list', meta: { auth: true } },
            { path: '/sale-return-detail/:id', component: require('./components/admin/SaleReturnDetail.vue').default, name: 'sale-return-list', meta: { auth: true } },


            // { path: '/purchase-return', component: require('./components/admin/ListPurchaseReturn.vue').default, name: 'purchase-return-list', meta: { auth: true } },
            { path: '/add-purchase-return', component: require('./components/admin/AddPurchaseReturn.vue').default, name: 'purchase-return-manage', meta: { auth: true } },
            { path: '/purchase-return-detail/:id', component: require('./components/admin/PurchaseReturnDetail.vue').default, name: 'purchase-return-list', meta: { auth: true } },

            { path: '/add-stock', component: require('./components/admin/Stock.vue').default, name: 'stock-manage', meta: { auth: true } },
            { path: '/stocks', component: require('./components/admin/ListStock.vue').default, name: 'stock-list', meta: { auth: true } },
            { path: '/stock-transfer', component: require('./components/admin/StockTransfer.vue').default, name: 'stock-transfer-list', meta: { auth: true } },
            { path: '/stock-transfers', component: require('./components/admin/ListStockTransfer.vue').default, name: 'stock-transfer-manage', meta: { auth: true } },

            { path: '/add-quotation', component: require('./components/admin/AddQuotation.vue').default, name: 'quotation-manage', meta: { auth: true } },
            { path: '/quotations', component: require('./components/admin/ListQuotation.vue').default, name: 'quotation-list', meta: { auth: true } },
            { path: '/add-sale-quotation', component: require('./components/admin/AddSaleQuotation.vue').default, name: 'quotation-sale-manage', meta: { auth: true } },
            { path: '/sale-quotations', component: require('./components/admin/ListSaleQuotation.vue').default, name: 'quotation-sale-list', meta: { auth: true } },
            { path: '/sale-quotation-detail/:id', component: require('./components/admin/SaleQuotationDetail.vue').default, name: 'quotation-sale-list', meta: { auth: true } },

            { path: '/products', component: require('./components/admin/ListProduct.vue').default, name: 'product-list', meta: { auth: true } },
            { path: '/add-product', component: require('./components/admin/AddProduct.vue').default, name: 'product-manage', meta: { auth: true } },
            { path: '/product/:id', component: require('./components/admin/AddProduct.vue').default, name: 'product-list', meta: { auth: true } },

            { path: '/import-export', component: require('./components/admin/ImportExport.vue').default, name: 'import-product',meta: { auth: true } },

            { path: '/verify-purchase-code', component: require('./components/admin/purchaseCode.vue').default, name: 'import-product', meta: { auth:true } },


            { path: '/home-setting', component: require('./components/admin/HomePageSetting.vue').default, name: 'home-page-builder', meta: { auth: true } },
            { path: '/slider-setting', component: require('./components/admin/SliderSetting.vue').default, name: 'slider-bannder-manage', meta: { auth: true } },
            { path: '/banner-setting', component: require('./components/admin/BannerSetting.vue').default, name: 'mobile-slider-bannder-list', meta: { auth: true } },



            { path: '/newslettercontact', component: require('./components/admin/Newslettercontact.vue').default, name: 'NewsletterContact-list', meta: { auth: true } },



            { path: '/blog', component: require('./components/admin/Blog.vue').default, name: 'blog-list', meta: { auth: true } },
            { path: '/blog-category', component: require('./components/admin/BlogCategory.vue').default, name: 'blog-manage', meta: { auth: true } },

            { path: '/constant-banner', component: require('./components/admin/ConstantBanner.vue').default, name: 'constant-bannder-list', meta: { auth: true } },
            { path: '/home-banner', component: require('./components/admin/HomeBanner.vue').default, name: 'parrallex-bannder-list', meta: { auth: true } },



            { path: '/menu-builder', component: require('./components/admin/MenuBuilder.vue').default, name: 'menu-builder', meta: { auth: true } },
            { path: '/content-page', component: require('./components/admin/ContentPage.vue').default, name: 'content-page-manage', meta: { auth: true } },

            { path: '/account_list', component: require('./components/admin/Account.vue').default, name: 'account-list', meta: { auth: true } },
            { path: '/ledger', component: require('./components/admin/Ledger.vue').default, name: 'ledger-report', meta: { auth: true } },
            { path: '/adjustment', component: require('./components/admin/AssetAdjustment.vue').default, name: 'assets-adjustment', meta: { auth: true } },
            { path: '/payment-adjustment', component: require('./components/admin/PaymentAdjustment.vue').default, name: 'payment', meta: { auth: true } },
            { path: '/reciept-adjustment', component: require('./components/admin/RecieptAdjustment.vue').default, name: 'reciepts', meta: { auth: true } },
            { path: '/expense-adjustment', component: require('./components/admin/ExpenseAdjustment.vue').default, name: 'expense', meta: { auth: true } },
            { path: '/profile', component: require('./components/admin/Profile.vue').default, name: 'Profile', meta: { auth: true } },
            { path: '/deliveryboy-list', component: require('./components/admin/DeliveryBoyList.vue').default, name: 'delivery-boy-list', meta: { auth: true } },
            { path: '/deliveryboy', component: require('./components/admin/ADdDeliveryBoy.vue').default, name: 'manage-delivery-boy', meta: { auth: true } },
            { path: '/deliveryboy/:id', component: require('./components/admin/ADdDeliveryBoy.vue').default, name: 'manage-delivery-boy', meta: { auth: true } },
            { path: '/stock-report', component: require('./components/admin/StockReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/out-of-stock-report', component: require('./components/admin/OutOfStockReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/stock-transfer-report', component: require('./components/admin/StockTransferReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/purchase-report', component: require('./components/admin/PurchaseReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/order-report', component: require('./components/admin/OrderReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/supplier-report', component: require('./components/admin/SupplierReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/supplier-due-payment/:id', component: require('./components/admin/SupplierDuePayment.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/supplier/pay/history', component: require('./components/admin/ListSupplierPayHistory.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/stock-history-report', component: require('./components/admin/InventoryReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/expense-report', component: require('./components/admin/ExpenseReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/profit-loss-report', component: require('./components/admin/ProfitLossReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/old-profit-loss-report', component: require('./components/admin/OldProfitLossReport.vue').default, name: 'manage-reports', meta: { auth: true } },
            { path: '/invoice-quotation', component: require('./components/admin/InvoiceQuotation.vue').default, name: 'manage-reports', meta: { auth: true } },

        
        ]
    },

    { path: '/pos', component: require('./components/admin/Pos.vue').default, name: 'pos', meta: { auth: true } },
    { path: '/login', component: require('./components/admin/Login.vue').default, name: 'login', meta: { redirectToDashboard: true } },
    { path: "/accessdenied", component: require('./components/AccessDenied.vue').default, name: 'accessdenied' },
    { path: "/*", component: require('./components/PageNotFound.vue').default, name: 'pageNotFound' },




]


const router = new VueRouter({
    routes,
    mode: 'history',
    root: '/'
})

router.beforeEach((to, from, next) => {


    var loggedIn = localStorage.getItem('loggedIn');
    if (to.matched.some(record => record.meta.auth) && (loggedIn == null || loggedIn == false)) {
        next('/login')
        return
    }
    var token = localStorage.getItem('token');

    if (loggedIn != null && token != null && to.matched.some(record => record.meta.auth)) {

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.post('/api/admin/token-validate', {}, config)
            .then(res => {

                if (res.status == 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('loggedIn');
                }
                // if (res.data.status == "Success") {
                //     loggedIn = true
                // }

            }).catch(error => {
                console.log(error);
                next('/login')
                return
            })
    }
    else {
        if (to.matched.some(record => record.meta.auth) && (loggedIn == null || loggedIn == false)) {
            next('/login')
            return
        }
    }

    // if (loggedIn == true && to.matched.some(record => record.meta.redirectToDashboard)) {
    //     next('/dashboard')
    // }

    next()
})


const app = new Vue({
    router
}).$mount('#kundol-body');