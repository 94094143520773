<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-12 col-xl-12">
                <div
                  class="
                    card card-custom
                    gutter-b
                    bg-transparent
                    shadow-none
                    border-0
                  "
                >
                  <div
                    class="
                      card-header
                      align-items-center
                      border-bottom-dark
                      px-0
                    "
                  >
                    <div class="card-title mb-0">
                      <h3 class="card-label mb-0 font-weight-bold text-body">
                        Profit And Loss Report
                      </h3>
                    </div>
                    <div class="icons d-flex"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card card-custom gutter-b bg-white border-0">
                  <div class="card-body">
                    <div class="row">

                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Time</label>
                         <!-- datepicker add -->
                    <div class="datepicker-trigger ">
                          <div id="datepicker"
                            class="input-group date bg-light"
                            data-date-format="mm-dd-yyyy">
                      <input
                        type="text"
                        class="form-control"
                        id="datepicker-trigger"
                        placeholder="Select date (MM-DD-YYYY)"
                        :value="formatDates(dateOne, dateTwo)"
                        readonly  >
                      <span class="input-group-addon d-flex justify-content-center align-items-center p-2">
                        <i class="fa-solid fa-calendar-days"></i>
                     </span>
                    </div>

                      <AirbnbStyleDatepicker
                        :trigger-element-id="'datepicker-trigger'"
                        :mode="'range'"
                        :fullscreen-mobile="true"
                        :date-one="dateOne"
                        :date-two="dateTwo"
                        @date-one-selected="val => { dateOne = val }"
                        @date-two-selected="val => { dateTwo = val }"
                        :show-action-buttons="true"
                         :close-after-select="true"
                      />
                    </div>

                        </div>
                      </div>
                      <div class="col-md-2">
                        <button
                          style="margin-top: 20px"
                          class="btn btn-warning text-white"
                          @click="fetchSaleReport('')"
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                    <div>
                      <div class="table-responsive" id="printableTable">
                        <div
                          id="productUnitTable_wrapper"
                          class="dataTables_wrapper no-footer"
                        >
                          <div
                            class="dataTables_length"
                            id="productUnitTable_length"
                          >
                            <label
                              >Show
                              <select
                                name="productUnitTable_length"
                                class=""
                                v-model="limit"
                                v-on:change="fetchSaleReport()"
                              >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                              </select>
                              entries</label
                            >
                          </div>
                          <table
                            id="productUnitTable"
                            class="display dataTable no-footer"
                            role="grid"
                          >
                            <thead class="text-body">
                              <tr role="row">
                                <th
                                  class="sorting"
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  aria-sort="ascending"
                                  aria-label="ID: activate to sort column descending"
                                  style="width: 31.25px"
                                  @click="sorting('id')"
                                  :class="
                                    (this.$data.sortType == 'asc' ||
                                      this.$data.sortType == 'ASC') &&
                                    this.$data.sortBy == 'id'
                                      ? 'sorting_asc'
                                      : (this.$data.sortType == 'desc' ||
                                          this.$data.sortType == 'DESC') &&
                                        this.$data.sortBy == 'id'
                                      ? 'sorting_desc'
                                      : 'sorting'
                                  "
                                >
                                  Pid
                                </th>
                                <th
                                  class="sorting"
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="stock: activate to sort column ascending"
                                  style="width: 95.5288px"
                                >
                                  Product Name
                                </th>
                                <th
                                  class="sorting"
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Phone No: activate to sort column ascending"
                                  style="width: 81.8109px"
                                >
                                  Buy Price
                                </th>
                                <th
                                  class="sorting"
                                  tabindex="0"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Phone No: activate to sort column ascending"
                                  style="width: 81.8109px"
                                >
                                  Sale Price
                                </th>

                                  <th
                                      class="sorting"
                                      tabindex="0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Phone No: activate to sort column ascending"
                                      style="width: 81.8109px"
                                  >
                                      Profit/Loss(single)
                                  </th>

                                  <th
                                      class="sorting"
                                      tabindex="0"
                                      rowspan="1"
                                      colspan="1"
                                      aria-label="Phone No: activate to sort column ascending"
                                      style="width: 81.8109px"
                                  >
                                      Qty
                                  </th>

                                <th
                                  class="no-sort sorting_disabled"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Action"
                                  style="width: 53.1891px"
                                >
                                Profit/Loss(Total)
                                </th>
                              </tr>
                            </thead>
                            <tbody class="kt-table-tbody text-dark">
                              <tr
                                class="kt-table-row kt-table-row-level-0 odd"
                                role="row"
                                v-for="(stock, i) in stocks"
                                v-bind:key="i"
                              >
                                <td class="sorting_1">
                                  {{ stock.product_id }}
                                </td>
                                <td>
                                  {{

                                    stock.product
                                      ? stock.product.detail
                                        ? stock.product.detail[0].title
                                        : ""
                                      : ""
                                  }}
                                </td>
                                <td>
                                  {{
                                    stock.buy_price
                                  }}
                                </td>

                                <td>
                                  {{
                                    stock.product_discount
                                  }}
                                </td>

                                  <td>
                                      {{
                                          stock.product_discount - stock.buy_price
                                      }}
                                  </td>

                                  <td>
                                      {{
                                          stock.total_qty
                                      }}
                                  </td>

                                <td>
                                  {{
                                    parseFloat(stock.product_discount*stock.total_qty) - parseFloat(stock.buy_price*stock.total_qty)
                                  }}
                                </td>
                              </tr>

                                  <tr v-if="stocks.length > 0">
                                      <td colspan="6">
                                          <h4 class="font-size-h4 font-size-bold text-dark text-right">
                                              Total Amount :
                                          </h4>
                                      </td>
                                      <td class=" font-size-bold font-size-h4">
                                          {{TotalAmount()}} Tk
                                      </td>
                                  </tr>
                            <tr v-else>
                                <td colspan="7">
                                    <h4 class="text-center">No Data Found</h4>
                                </td>
                            </tr>

                            </tbody>

                          </table>
                           <!-- total add -->
<!--                          <div class="d-flex align-items-center  m-0  justify-content-end my-1">-->
<!--                              <th class="border-0">-->
<!--                                  <h4 class="font-size-h3 mb-0 font-size-bold text-dark">-->
<!--                                      Total Amount :-->
<!--                                    </h4>-->
<!--                                  </th>-->
<!--                               <td class="border-0 justify-content-end d-flex text-black-50 font-size-bold font-size-h3">-->
<!--                                  {{TotalAmount()}} Tk-->
<!--                               </td>-->
<!--                              </div>-->


                          <ul class="pagination pagination-sm m-0 float-right">
                            <li
                              v-bind:class="[
                                { disabled: !pagination.prev_page_url },
                              ]"
                            >
                              <button
                                class="page-link"
                                href="#"
                                @click="
                                  fetchSaleReport(pagination.prev_page_url)
                                "
                              >
                                Previous
                              </button>
                            </li>

                            <li class="disabled">
                              <a class="page-link text-dark" href="#"
                                >Page {{ pagination.current_page }} of
                                {{ pagination.last_page }}</a
                              >
                            </li>

                            <li
                              v-bind:class="[
                                { disabled: !pagination.next_page_url },
                              ]"
                              class="page-item"
                            >
                              <button
                                class="page-link"
                                href="#"
                                @click="
                                  fetchSaleReport(pagination.next_page_url)
                                "
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorHandling from "./../../ErrorHandling";
import format from 'date-fns/format'
export default {
  data() {
    return {
      dateFormat: 'MMMM D, YYYY',
      dateOne: '',
      dateTwo: '',
      timeOne: '',
      timeTwo: '',
      sortBy: "id",
      sortType: "ASC",
      limit: 10,
      error_message: "",
      pagination: {},
      stocks: [],
      warehouses: [],
      products: [],
      warehouse_id: "",
      product_id: "",
      time: "",
      category_id: "",
      product_categories: [],
      token: [],
      errors: new ErrorHandling(),
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
  },

  methods: {

    // total
    TotalAmount() {
      let total = 0;
    return this.stocks.reduce( (acc, item) => {
        total += parseFloat(item.product_discount*item.total_qty) - parseFloat(item.buy_price*item.total_qty);
        return total;
    }, 0)
   },

    formatDates(dateOne, dateTwo) {
      let formattedDates = ''
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
        // formar change
        var splitDate = this.dateOne.split('-');
        if(splitDate.count == 0){
            return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        this.timeOne = year + '-' + month + '-' + day;

        // console.log(this.timeOne)

      }
      if (dateTwo) {
        formattedDates += ' - ' + format(dateTwo, this.dateFormat)
         // formar change
         var splitDate = this.dateTwo.split('-');
        if(splitDate.count == 0){
            return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        this.timeTwo = year + '-' + month + '-' + day;
        // console.log(this.timeTwo)
      }
      // console.log(dateOne, dateTwo);
      return formattedDates
    },

    fetchSaleReport(page_url) {
      this.$parent.loading = true;
      let vm = this;
      page_url = page_url || "/api/admin/reports/profit-los";
      var arr = page_url.split("?");

      if (arr.length > 1) {
        page_url += "&limit=" + this.limit;
      } else {
        page_url += "?limit=" + this.limit;
      }

        var d = new Date();

        var datestring = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate();

      console.log(this.timeOne, this.timeTwo,datestring);

      if(!this.timeTwo)
      {
          this.timeOne = this.timeTwo = datestring;
      }

      page_url += "&sortBy=" + this.sortBy + "&sortType=" + this.sortType+ "&from=" + this.timeOne+ "&to=" + this.timeTwo;
      // page_url +=
      //   "&warehouse_id=" +
      //   this.warehouse_id +
      //   "&category_id=" +
      //   this.category_id +
      //   "&product_id=" +
      //   this.product_id +
      //   "&time=" +
      //   this.time;

      var responseData = {};

      axios
        .get(page_url, this.token)
        .then((res) => {
          this.stocks = res.data.data;
          vm.makePagination(res.data, res.data.links);
        })
        .finally(() => (this.$parent.loading = false));
    },

    makePagination(meta, links) {
      let pagination = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        next_page_url: meta.next_page_url,
        prev_page_url: meta.prev_page_url,
      };

      this.pagination = pagination;
    },
    fetchWarehouses(page_url) {
      this.$parent.loading = true;
      let vm = this;
      page_url = page_url || "/api/admin/warehouse?getAllData=1";
      axios
        .get(page_url, this.token)
        .then((res) => {
          this.warehouses = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    fetchProductCategories(page_url) {
      this.$parent.loading = true;
      let vm = this;
      page_url = page_url || "/api/admin/category?getAllData=1";
      axios
        .get(page_url, this.token)
        .then((res) => {
          this.product_categories = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    fetchProducts(page_url) {
      this.$parent.loading = true;
      let vm = this;
      page_url = page_url || "/api/admin/product?getAllData=1";
      axios
        .get(page_url, this.token)
        .then((res) => {
          this.products = res.data.data;
        })
        .finally(() => (this.$parent.loading = false));
    },
    sorting(sortBy) {
      this.sortBy = sortBy;
      this.sortType =
        this.sortType == "asc" || this.sortType == "ASC"
          ? (this.sortType = "desc")
          : (this.sortType = "asc");
      this.fetchSaleReport("");
    },
  },
  mounted() {
    var token = localStorage.getItem("token");
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    this.fetchSaleReport("");
    // this.fetchWarehouses();
    // this.fetchProductCategories();
    // this.fetchProducts();
  },
};
</script>
