import { render, staticRenderFns } from "./SupplierDuePayment.vue?vue&type=template&id=308fc9ef&"
import script from "./SupplierDuePayment.vue?vue&type=script&lang=js&"
export * from "./SupplierDuePayment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports