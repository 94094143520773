<template>
    <div>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label>Invoice No.</label>
            <input type="text" class="form-control" v-model="form.invoice_no">
            <p v-if="errors.invoice_no" class="error">{{ errors.invoice_no[0] }}</p>
          </div>
          <div class="form-group">
            <label>Client</label>
            <input type="text" class="form-control" v-model="form.client">
            <p v-if="errors.client" class="error">{{ errors.client[0] }}</p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>Client Address</label>
            <textarea class="form-control" v-model="form.client_address"></textarea>
            <p v-if="errors.client_address" class="error">{{ errors.client_address[0] }}</p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label>Title</label>
            <input type="text" class="form-control" v-model="form.title">
            <p v-if="errors.title" class="error">{{ errors.title[0] }}</p>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label>Invoice Date</label>
              <input type="date" class="form-control" v-model="form.invoice_date">
              <p v-if="errors.invoice_date" class="error">{{ errors.invoice_date[0] }}</p>
            </div>
            <div class="col-sm-6">
              <label>Due Date</label>
              <input type="date" class="form-control" v-model="form.due_date">
              <p v-if="errors.due_date" class="error">{{ errors.due_date[0] }}</p>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div v-if="errors.products_empty">
        <p class="alert alert-danger">{{ errors.products_empty[0] }}</p>
        <hr>
      </div>
      <table class="table table-bordered table-form">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Unit Price</th>
            <th>Qty</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in form.products" :key="index">
            <td class="table-name" :class="{'table-error': errors['products.' + index + '.name']}">
              <input class="table-control" type="text" v-model="product.name"></input>
            </td>
            <td class="table-price" :class="{'table-error': errors['products.' + index + '.price']}">
              <input type="text" class="table-control" v-model="product.price" @input="calculateTotal(product)">
            </td>
            <td class="table-qty" :class="{'table-error': errors['products.' + index + '.qty']}">
              <input type="text" class="table-control" v-model="product.qty" @input="calculateTotal(product)">
            </td>
            
            <td class="table-total">
              <span class="table-text">{{ product.qty * product.price }}</span>
            </td>
            <td class="table-remove">
              <a @click="removeProduct(index)" class="btn btn-danger white">&times;</a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="table-empty" colspan="2">
              <a @click="addLine" class="table-add_line btn btn-primary white">Add Line</a>
            </td>
            <td class="table-label">Sub Total</td>
            <td class="table-amount">{{ subTotal }}</td>
          </tr>
          <tr>
            <td class="table-empty" colspan="2"></td>
            <td class="table-label">Discount</td>
            <td class="table-discount" :class="{'table-error': errors.discount}">
              <input type="text" class="table-discount_input" v-model="form.discount">
            </td>
          </tr>
          <tr>
            <td class="table-empty" colspan="2"></td>
            <td class="table-label">Grand Total</td>
            <td class="table-amount">{{ grandTotal }}</td>
          </tr>
        </tfoot>
      </table>
      <div>
        <button class="btn btn-secondary white" type="submit" @click="generateInvoice">Generate Invoice</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          invoice_no: '',
          client: '',
          client_address: '',
          title: '',
          invoice_date: '',
          due_date: '',
          products: [{ name: '', price: '', qty: '' , total: 0}],
          discount: '',
          grand_total:0,
          sub_total:0,
        },
        token: [],
        csrf: document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
        errors: {
          invoice_no: '',
          client: '',
          client_address: '',
          title: '',
          invoice_date: '',
          due_date: '',
          products_empty: '',
          discount: '',
          // Add more error fields if needed
        }
      };
    },
    computed: {
      subTotal() {
        return this.form.products.reduce((acc, product) => acc + (product.price * product.qty || 0), 0);
      },
      grandTotal() {
        return this.subTotal - (this.form.discount || 0);
      }
    },
    methods: {
      calculateTotal(product) {
       product.total = product.qty * product.price;
      },
      addLine() {
        this.form.products.push({ name: '', price: '', qty: '', total: 0 });
      },
      removeProduct(index) {
        this.form.products.splice(index, 1);
      },
      generateInvoice() {
         // Calculate the grand total before submission
    this.form.sub_total = this.form.products.reduce((acc, product) => acc + (product.price * product.qty || 0), 0);
    this.form.grand_total = this.subTotal - (this.form.discount || 0);
    
    
         // Validate product data before submission
      this.errors.product_errors = [];
      this.form.products.forEach((product, index) => {
        if (!product.name || !product.price || !product.qty) {
          this.errors.product_errors.push(`Product ${index + 1} is missing data.`);
        }
      });
         console.log(this.errors.product_errors);
           const propertyNames = Object.keys(this.errors.product_errors);
            propertyNames.forEach(key => {
           this.$toaster.error(this.errors.product_errors[key]);
        });

      // If there are product errors, stop submission and display errors
      if (this.errors.product_errors.length > 0) {
        return;
      }
      this.$confirm("Do you want to print invoice ?").then(() => {
      // Make an HTTP POST request to the Laravel endpoint
      axios.post('/api/admin/quotation-invoices', { data: this.form }, this.token)
        .then(response => {
          // Handle success
          console.log(response.data.message);
          this.$toaster.success(response.data.message);
            setTimeout(() => {
                this.customerAddressModel = false;
              var print_url = "/print-invoice-quotation/" + response.data.data;
              window.open(print_url, "_blank");
            }, 500);
        })
        .catch(error => {
          // Handle error
          console.error('Error storing invoice:', error);
        });
      });
    },
    },
    mounted() {
    var token = localStorage.getItem("token");
    this.token = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    this.fetchstocks();
  },
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  